import React from 'react';
import { Filter, Table, Confirmation } from 'components';
import { connect } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class UsersContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmation: {
                isVisible: false,
                header: "",
                description: "",
                onCancel: null,
                onConfirm: null
            }
        }
    }

    componentDidMount() {
        const {
            fetchUsers,
            token,
            clearUsers
        } = this.props
        clearUsers()
        fetchUsers(token)
    }

    onToggleActivate = async(item, target) => {
        const {
            toggleActivateUser,
            toastPending,
            token
        } = this.props;
        var param = {
            id: item.id,
            type: target,
            role: "user"
        }
        this.onCancelConfirmation();
        var json = await toggleActivateUser(token, param)
        toastPending({ message: json.message, toastType: json.status_code })
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    onCancelConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onSearch = (keyword) => {
        const { users } = this.props;
        var filteredData = users.filter((item) => {
            var firstname = item.firstname + " ";
            var name = firstname.concat(item.lastname);
            if(name.toLowerCase().includes(keyword.toLowerCase())) {
                return item;
            }
            return false;
        });
        this.setState({
            keyword: keyword,
            filteredData: filteredData
        });
    }

    onFilter = async(filters) => {
        var filterDate = await this.filterDate(filters);
        var filterStatus = await this.filterStatus(filterDate, filters);
        var filteredData = filterStatus;

        this.setState({
            isFiltering: false,
            keyword: "filter",
            filteredData: filteredData
        })
    }

    filterDate = (filters) => {
        const { users } = this.props;
        var isFilterDate = filters.filter((item) => item.target === "created_date");
        if(isFilterDate.length > 0) {
            var filteredData = [];
            var date_from = filters.find((item) => item.target === "created_date" && item.key === "date_from")    
            var date_to = filters.find((item) => item.target === "created_date" && item.key === "date_to");
            if(date_to) {
                var range = moment.range(date_from.value, date_to.value);
                
                filteredData = users.filter((item) => {
                    if(moment(item.created_at).within(range)) {
                        return item;
                    }
                    return false;
                });
            }
            else {
                filteredData = users.filter((item) => {
                    var created_at = moment(item.created_at).format("YYYY-MM-DD");
                    var filterDate = moment(date_from.value).format("YYYY-MM-DD");

                    if(moment(created_at).isSame(filterDate)) {
                        return item;
                    }
                    return false;
                });
            }

            return filteredData;
        }
        
        return users;
    }

    filterStatus = (data, filters) => {
        var filterStatus = filters.filter((item) => item.target === "status");
        if(filterStatus.length > 0) {
            var filteredData = [];
            filterStatus.map((filter_item) => {
                if(filter_item.key === "active" && filter_item.value) {
                    data.map((item) => {
                        if(item.is_active) {
                            filteredData.push(item)
                        }
                        return false;
                    })
                }
                else if(filter_item.key === "deactive" && filter_item.value) {
                    data.map((item) => {
                        if(!item.is_active) {
                            filteredData.push(item)
                        }
                        return false;
                    })
                }
                else {
                    filteredData = data;
                }
                return false;
            })

            return filteredData;
        }

        return data;
    }

    render() {

        const { users } = this.props;
        const { confirmation, keyword, filteredData } = this.state;

        const columns = [
            {
                Header: 'No',
                id: 'no',
                accessor: (props, index) => (
                    <div className="table__body__row__column__text-style">{index + 1}</div>
                )
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{props.firstname} {props.lastname}</div>
                )
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: props => (
                    <div className="table__body__row__column__text-style">{props.value}</div>
                )
            },
            {
                Header: 'Created Date',
                accessor: 'created_date',
                Cell: props => {
                    return (
                        <div className="table__body__row__column__text-style">{moment(props.value).format("DD MMM YYYY")}</div>
                    ) 
                    
                },
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: props => {
                    var status = props.is_active ? "success" : "default";
                    return (
                        <div className={`table__body__row__column__btn-style table__body__row__column__btn-style__${status}`}>{props.is_active ? "Active" : "Deactive"}</div>
                    ) 
                    
                },
            },
            {
                Header: 'Actions',
                id: "id",
                accessor: props => {
                    return(
                        <DropdownButton
                            className="table__body__row__column__dropdown"
                            key="up"
                            drop="up"
                            variant="secondary"
                            title={<i className="material-icons">more_horiz</i>}
                        >
                            {props.is_active ? (
                                <Dropdown.Item eventKey="2" 
                                    onClick={() => this.openConfirmation(
                                        "Are you sure",
                                        "Are you sure to deactivate this user? (" + props.firstname + " " + props.lastname + ")",
                                        () => this.onCancelConfirmation(),
                                        () => this.onToggleActivate(props, "deactivate")
                                    )}
                                    className="dropdown-item__activate"
                                >
                                    Deactivate
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item eventKey="2" 
                                    onClick={() => this.openConfirmation(
                                        "Are you sure",
                                        "Are you sure to activate this user",
                                        () => this.onCancelConfirmation(),
                                        () => this.onToggleActivate(props, "activate")
                                    )}
                                    className="dropdown-item__activate"
                                >
                                    Activate
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                    )
                }
            }
        ];

        const filterOptions = [
            {
                type: "date",
                key: "created_date",
                label: "Date"
            },
            {
                type: "option",
                key: "status",
                label: "Status",
                items: [
                    { key: "active", label: "Active" },
                    { key: "deactive", label: "Deactive" }
                ]
            }
        ];

        var data = keyword ? filteredData : users;

        return(
            <div className="dashboard">

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />

                <div className="dashboard__header">
                    <div className="flex flex__sm">
                        <h1 className="header" style={{ marginRight: 20 }}>User</h1>
                        <Filter 
                            filterOptions={filterOptions}
                            withFilter
                            isFilterVisible={this.state.isFiltering}
                            onCancel={() => this.setState({ isFiltering: false })}
                            onFilter={() => this.setState({ isFiltering: !this.state.isFiltering })}
                            onApplyFilter={(e) => this.onFilter(e)}
                            onSearch={(e) => this.onSearch(e)}
                        />
                    </div>
                </div>
                <div className="dashboard__body">
                    <Table 
                        columns={columns}
                        data={data}
                    />
                </div>
            </div>
        )
    }

}

const mapsStateToProps = ({ authenticated, users }) => {
    return {
        token: authenticated.token,
        users: users.lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/UsersRedux");

    return {
      ...ownProps,
      ...stateProps,
      fetchUsers: async(
        token
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchUsers(dispatch, token)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toggleActivateUser: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.toggleActivateUser(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearUsers: () => dispatch({ type: "CLEAR_USERS" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(UsersContainer);