import React from 'react';
import { Detail, DetailContent, DetailInformation, Input, Modal } from 'components';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import "./styles.scss";
import { Config, Tools, Images } from 'common';

class ConsignmentDetailContainer extends React.PureComponent {
    
    render() {

        const { data, isVisible, material_categories, fleet_categories, payment_detail } = this.props;
        const { innerWidth } = window;
        
        var fleet_category = {};
        var material_category = {};
        
        if(data) {
            fleet_category = fleet_categories.find((item) => item.id === data.fleet_category_id);
            material_category = material_categories.find((item) => item.id === data.material_category_id);

            if(data.status === Config.savedLocallyStatusKey) {
                if(Tools.isNotEmpty(data.fleet_category)) {
                    fleet_category = fleet_categories.find((item) => item.id === data.fleet_category.id);
                }
                if(Tools.isNotEmpty(data.material_category)) {
                    material_category = material_categories.find((item) => item.id === data.material_category.id);
                }
            }
        }
        
        return(
          <Modal
            reference={(ref) => this.rejection_modal = ref}
            isVisible={isVisible}
            className="modal__full modal__form modal__detail"
          >
            <button className={`button__link modal__detail__closer`} onClick={() => this.props.onClose()}>
              <img src={Images.close} alt="close" />
            </button>

            <Detail
              style={{ width: '100%', margin: 0 }}
              className="detail__modal"
              containerClassName="detail__modal__container"
            >
              <DetailContent
                style={innerWidth < 768 ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 } : { borderBottomLeftRadius: 10, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                header="Consignment Detail"
              >
                <div className="details__item" style={{ paddingTop: 20 }}>
                  <h4 className="details__item__header">Pickup</h4>
                  <div className="details__item__group">
                    <p className="label">Sender Name</p>
                    <p className="text">{data ? data.pickup_name : ""}</p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Mobile Number</p>
                    <p className="text">
                      {
                                        data ? 
                                            data.status === Config.savedLocallyStatusKey ?
                                                Tools.isNotEmpty(data.pickup_mobile_prefix) ? data.pickup_mobile_prefix.value + " " + data.pickup_mobile_number : ""
                          : data.pickup_mobile_prefix + " " + data.pickup_mobile_number
                        : ""
                                    }
                    </p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Address</p>
                    <p className="text">{data ? data.pickup_address_one + "( " + data.pickup_address_two + " )" : ""}</p>
                  </div>
                  <Row>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">City</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.pickup_cities) ? data.pickup_cities.value : ""
                              : data.pickup_cities
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">State</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.pickup_states) ? data.pickup_states.value : ""
                              : data.pickup_states
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Country</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.pickup_countries) ? data.pickup_countries.value : ""
                              : data.pickup_countries
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Postal Code</p>
                        <p className="text">{data ? data.pickup_postal_code : ""}</p>
                      </div>
                    </Col>
                  </Row>
                  <div className="details__item__group">
                    <p className="label">Pickup Date</p>
                    <p className="text">
                      {
                                        data ? 
                                            Tools.isNotEmpty(data.pickup_time) && Tools.isNotEmpty(data.pickup_date) ?
                                                data.status === Config.savedLocallyStatusKey ?
                                                    data.pickup_time.value.replace(/_/g, " ") + " - " + moment(data.pickup_date).format("DD MMM YYYY HH:mm")
                            : 
                                                    data.pickup_time.replace(/_/g, " ") + " - " + moment(data.pickup_date).format("DD MMM YYYY HH:mm")
                          : ""
                        : ""
                                    }
                    </p>
                  </div>
                </div>

                <div className="details__item" style={{ paddingTop: 20 }}>
                  <h4 className="details__item__header">Delivery</h4>
                  <div className="details__item__group">
                    <p className="label">Receiver Name</p>
                    <p className="text">{data ? Tools.isNotEmpty(data.delivery_name) ? data.delivery_name : "-" : "-"}</p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Mobile Number</p>
                    <p className="text">
                      {
                                        data ? 
                                            data.status === Config.savedLocallyStatusKey ?
                                                Tools.isNotEmpty(data.delivery_mobile_prefix) ? data.delivery_mobile_prefix.value + " " + data.delivery_mobile_number : "-"
                          : data.delivery_mobile_prefix + " " + data.delivery_mobile_number
                        : ""
                                    }
                    </p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Address</p>
                    <p className="text">
                      {
                                        data ? 
                                            Tools.isNotEmpty(data.delivery_address_one) && Tools.isNotEmpty(data.delivery_address_two) ?
                                                data.delivery_address_one + "( " + data.delivery_address_two + " )"
                          : "-"
                        : "-"
                                    }
                    </p>
                  </div>
                  <Row>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">City</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.delivery_cities) ? data.delivery_cities.value : "-"
                              : data.delivery_cities
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">State</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.delivery_states) ? data.delivery_states.value : "-"
                              : data.delivery_states
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Country</p>
                        <p className="text">
                          {
                                                data ? 
                                                    data.status === Config.savedLocallyStatusKey ?
                                                        Tools.isNotEmpty(data.delivery_countries) ? data.delivery_countries.value : "-"
                              : data.delivery_countries
                            : ""
                                            }
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Postal Code</p>
                        <p className="text">{data ? Tools.isNotEmpty(data.delivery_postal_code) ? data.delivery_postal_code : "-"  : "-"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="details__item" style={{ paddingTop: 20 }}>
                  <h4 className="details__item__header">Material</h4>
                  <div className="details__item__group">
                    <p className="label">Material Category</p>
                    <p className="text">
                      {
                                        material_category ? 
                                            material_category.name  
                                        : "-"
                                    }
                    </p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Material Detail</p>
                    <p className="text">{data ? Tools.isNotEmpty(data.material_detail) ? data.material_detail : "-" : "-"}</p>
                  </div>
                  <Row>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Weight</p>
                        <p className="text">{data ? Tools.isNotEmpty(data.weight) ? data.weight + " Kg" : "-" : "-"}</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="details__item__group">
                        <p className="label">Transportation Mode</p>
                        <p className="text">
                          {fleet_category ? fleet_category.name : "-"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </DetailContent>

              <DetailInformation>
                <div className="details__information" style={innerWidth < 768 ? { borderRadius: 10 } : { borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderBottomRightRadius: 0 }}>
                  <div className="details__item__group" style={{ marginBottom: 30 }}>
                    <p className="label label__lg">Total Cost</p>
                    <p className="text text__bold text__lg">{data ? Tools.isNotEmpty(data.material_price) ? data.material_price : "0" : "0"} INR</p>
                  </div>
                  <div className="details__item__group">
                    <p className="label">Consignor GST Number</p>
                    <p className="text text__bold">{data ? Tools.isNotEmpty(data.gst_number) ? data.gst_number : "-" : "-"}</p>
                  </div>
                  {/* <div className="details__item__group details__item__group__height">
                                <Input
                                    type="checkbox"
                                    value={data ? data.is_insurance : ""}
                                    label="Prompt for insurance"
                                    info="With insurance 500INR."
                                    style={{ margin: 0, marginBottom: 20 }}
                                    inputProps={{
                                        disabled: true
                                    }}
                                />  
                            </div> */}
                </div>
                { payment_detail[0]?.payment &&
                  <div className="details__information" style={innerWidth < 768 ? { borderRadius: 10 } : { borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0, marginTop: 12 }}>
                    <div className="details__item__group" style={{ marginBottom: 30 }}>
                      <p className="label label__lg">Payment Detail</p>
                      <p className="text text__bold text__lg">{payment_detail[0]?.payment?.payment_id}</p>
                    </div>
                    <div className="details__item__group">
                      <p className="label">Pickup Name</p>
                      <p className="text text__bold">{payment_detail[0]?.consignment?.pickup_name}</p>
                    </div>
                    <div className="details__item__group">
                      <p className="label">Logistic Company</p>
                      <p className="text text__bold">{payment_detail[0]?.payment?.logistic_partner?.name}</p>
                    </div>
                    <div className="details__item__group">
                      <p className="label">Price</p>
                      <p className="text text__bold">{payment_detail[0]?.payment?.amount + " INR"}</p>
                    </div>
                    <div className="details__item__group">
                      <p className="label">Status</p>
                      <p className="text text__bold">{payment_detail[0]?.payment?.payment_status === 151 ? "Created" : payment_detail[0]?.payment?.payment_status === 152 ? "Captured" : "Failed"}</p>
                    </div>
                  </div>
                }
              </DetailInformation>

            </Detail>

          </Modal>
        )
    }

}

const mapsStateToProps = ({ material_categories, fleet_categories, auction }) => {
    return {
        material_categories: material_categories.lists,
        fleet_categories: fleet_categories.lists,
        payment_detail: auction.payment_detail
    }
}
export default connect(mapsStateToProps)(ConsignmentDetailContainer);