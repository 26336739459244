import React from "react";
import { Table, Confirmation, Filter, Button } from "components";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import * as Moment from "moment";
import { Tools, Images, navigate, Config } from "common";
import ConsignmentDetailContainer from "./Detail";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

class ConsignmentsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      confirmation: {
        isVisible: false,
        header: "",
        description: "",
        onCancel: null,
        onConfirm: null,
      },
      loaded: false,
    };
  }

  async componentDidMount() {
    const {
      clearConsignments,
      fetchConsignments,
      fetchConsignmentStatus,
      fetchMaterialCategories,
      fetchFleetCategories,
      token,
      userInfo,
    } = this.props;
    clearConsignments();
    var id = userInfo.role === "admin" ? null : userInfo.id;
    await fetchConsignments(token, id);
    await fetchConsignmentStatus(token);
    await fetchMaterialCategories(token);
    await fetchFleetCategories(token);

    this.setState({
      loaded: true,
    });
  }

  openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
    this.setState({
      confirmation: {
        isVisible: true,
        header: headerText,
        description: descriptionText,
        onCancel: onCancel,
        onConfirm: onConfirm,
      },
    });
  };

  onCancelConfirmation = () => {
    this.setState({
      confirmation: {
        isVisible: false,
      },
    });
  };

  onSearch = (keyword) => {
    const { consignments } = this.props;
    var filteredData = consignments.filter(
      (item) =>
        item.order_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.id.toString().includes(keyword)
    );

    this.setState({
      keyword: keyword,
      filteredData: filteredData,
    });
  };

  onFilter = async (filters) => {
    var filterDate = await this.filterDate(filters);
    var filterStatus = await this.filterStatus(filterDate, filters);
    var filterFleetCategories = await this.filterFleetCategories(
      filterStatus,
      filters
    );
    var filterMaterialCategories = await this.filterMaterialCategories(
      filterFleetCategories,
      filters
    );
    var finalData = filterMaterialCategories;
    var filteredData = finalData;

    this.setState({
      isFiltering: false,
      keyword: "filter",
      filteredData: filteredData,
    });
  };

  filterDate = (filters) => {
    const { consignments } = this.props;
    var isFilterDate = filters.filter((item) => item.target === "created_date");
    if (isFilterDate.length > 0) {
      var filteredData = [];
      var date_from = filters.find(
        (item) => item.target === "created_date" && item.key === "date_from"
      );
      var date_to = filters.find(
        (item) => item.target === "created_date" && item.key === "date_to"
      );
      if (date_to) {
        var range = moment.range(date_from.value, date_to.value);

        filteredData = consignments.filter((item) => {
          if (moment(item.created_at).within(range)) {
            return item;
          }
          return false;
        });
      } else {
        filteredData = consignments.filter((item) => {
          var created_at = moment(item.created_at).format("YYYY-MM-DD");
          var filterDate = moment(date_from.value).format("YYYY-MM-DD");

          if (moment(created_at).isSame(filterDate)) {
            return item;
          }
          return false;
        });
      }
      return filteredData;
    }

    return consignments;
  };

  filterStatus = async (data, filters) => {
    const { status } = this.props;

    var filterStatus = filters.filter((item) => item.target === "status");
    if (filterStatus.length > 0) {
      var filteredData = [];
      filterStatus.map((filter_item) => {
        var selectedStatus = status.find(
          (item) => item?.status_name === filter_item.key
        );
        data.map((item) => {
          if (parseFloat(item.status) === parseFloat(selectedStatus.id)) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });
      return filteredData;
    }

    return data;
  };

  filterFleetCategories = async (data, filters) => {
    const { fleet_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "fleet_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = fleet_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.fleet_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });
      return filteredData;
    }

    return data;
  };

  filterMaterialCategories = async (data, filters) => {
    const { material_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "material_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = material_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.material_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });
      return filteredData;
    }

    return data;
  };

  handlePublish = async (params) => {
    this.onCancelConfirmation();
    const { publishConsignment, token, userInfo, toastPending } = this.props;
    var data = {
      ...params,
      status: 102,
    };
    var json = await publishConsignment(token, userInfo.id, data);
    toastPending({ message: json.message, toastType: json.status_code });
  };

  render() {
    const {
      consignments,
      userInfo,
      fetchPaymentDetail,
      clearPaymentDetail,
      token,
    } = this.props;
    const { confirmation, keyword, filteredData } = this.state;

    const columns = [
      {
        Header: "No",
        id: "no",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">
            {index + 1}
          </div>
        ),
      },
      {
        Header: "Consignment ID",
        id: "consignment_id",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">{props.id}</div>
        ),
      },
      // {
      //     Header: 'Order Name',
      //     id: 'name',
      //     accessor: props => (
      //         <div className="table__body__row__column__text-style">{props.order_name}</div>
      //     )
      // },
      {
        Header: "Sender",
        id: "sender",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.pickup_name}
          </div>
        ),
      },
      {
        Header: "Receiver",
        id: "receiver",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.delivery_name}
          </div>
        ),
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        Cell: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {moment(props.value).format("DD MMM YYYY")}
            </div>
          );
        },
      },
      {
        Header: "Fleet Category",
        id: "fleet_category",
        accessor: (props) => {
          const { fleet_categories } = this.props;
          var category = fleet_categories.find(
            (item) =>
              parseFloat(item.id) === parseFloat(props.fleet_category_id)
          );
          return (
            <div className="table__body__row__column__text-style">
              {category ? category.name : ""}
            </div>
          );
        },
      },
      {
        Header: "Material Category",
        id: "material_category",
        accessor: (props) => {
          const { material_categories } = this.props;
          var category = material_categories.find(
            (item) =>
              parseFloat(item.id) === parseFloat(props.material_category_id)
          );
          return (
            <div className="table__body__row__column__text-style">
              {category ? category.name : ""}
            </div>
          );
        },
      },
      {
        Header: "Status",
        id: "status",
        accessor: (props) => {
          const { status } = this.props;
          var consignment_status = status.find(
            (item) => parseFloat(item.status_code) === parseFloat(props.status)
          );

          return (
            <div
              className={`table__body__row__column__btn-style table__body__row__column__btn-style__primary`}
            >
              {consignment_status ? consignment_status?.status_name : ""}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (props) => {
          return (
            <DropdownButton
              className="table__body__row__column__dropdown center"
              key="up"
              drop="up"
              variant="secondary"
              title={<i className="material-icons">more_horiz</i>}
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() =>
                  this.setState({ selectedData: props }, () => {
                    this.setState({ openDetail: true });
                    clearPaymentDetail();
                    fetchPaymentDetail(
                      token,
                      props.id,
                      "consigment?consigment_id="
                    );
                  })
                }
                className="dropdown-item__detail"
              >
                See Detail
              </Dropdown.Item>

              {userInfo.role === "user" && props.status === 101 && (
                <Dropdown.Item
                  eventKey="2"
                  onClick={() =>
                    this.openConfirmation(
                      "Are you sure",
                      "Are you sure to publish this order? (" +
                        props.order_name +
                        ")",
                      () => this.onCancelConfirmation(),
                      () => this.handlePublish(props)
                    )
                  }
                  className="dropdown-item__publish"
                >
                  Publish
                </Dropdown.Item>
              )}

              {userInfo.role === "user" &&
                (props.status === 101 ||
                  props.status === Config.savedLocallyStatusKey) && (
                  <Dropdown.Item
                    eventKey="2"
                    onClick={() =>
                      navigate(
                        this.props.history,
                        "/consignment/edit/" + props.id
                      )
                    }
                    className="dropdown-item__edit"
                  >
                    Edit
                  </Dropdown.Item>
                )}
            </DropdownButton>
          );
        },
      },
    ];

    const data = keyword ? filteredData : consignments;

    const filterOptions = [
      {
        type: "date",
        key: "created_date",
        label: "Date",
      },
      {
        type: "option",
        key: "status",
        label: "Status",
        items: Tools.generateConsignmentStatusFilter(),
      },
      {
        type: "option",
        key: "material_categories",
        label: "Material Categories",
        items: Tools.generateMaterialCategoriesFilter(),
      },
      {
        type: "option",
        key: "fleet_categories",
        label: "Fleet Categories",
        items: Tools.generateFleetCategoriesFilter(),
      },
    ];

    return (
      <div className="dashboard">
        <Confirmation
          isVisible={confirmation.isVisible}
          header={confirmation.header}
          description={confirmation.description}
          confirmationText="Yes"
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
        />

        <ConsignmentDetailContainer
          isVisible={this.state.openDetail}
          data={this.state.selectedData}
          onClose={() => this.setState({ openDetail: false })}
        />

        <div className="dashboard__header">
          <div className="flex flex__sm">
            <h1 className="header" style={{ marginRight: 20 }}>
              Consignment
            </h1>
            <Filter
              filterOptions={filterOptions}
              withFilter
              isFilterVisible={this.state.isFiltering}
              onCancel={() => this.setState({ isFiltering: false })}
              onFilter={() =>
                this.setState({ isFiltering: !this.state.isFiltering })
              }
              onApplyFilter={(e) => this.onFilter(e)}
              onSearch={(e) => this.onSearch(e)}
            />
          </div>

          {userInfo.role === "user" && (
            <div className="flex flex__sm">
              <Button
                icon={Images.plus}
                iconPosition="before-text"
                buttonText="Add Consignment"
                type="dashboard"
                size="sm"
                style={{ marginRight: 0 }}
                onClick={() => {
                  navigate(this.props.history, "/consignment/create");
                }}
              />
            </div>
          )}
        </div>
        <div className="dashboard__body">
          {this.state.loaded && <Table columns={columns} data={data} />}
        </div>
      </div>
    );
  }
}

const mapsStateToProps = ({
  authenticated,
  consignment,
  material_categories,
  fleet_categories,
  users,
}) => {
  return {
    token: authenticated.token,
    userInfo: authenticated.userInfo,
    consignments: consignment.lists,
    material_categories: material_categories.lists,
    fleet_categories: fleet_categories.lists,
    status: consignment.status,
    users: users.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ConsignmentRedux");
  const {
    actions: material_categories_actions,
  } = require("redux/MaterialCategoriesRedux");
  const {
    actions: fleet_categories_actions,
  } = require("redux/FleetCategoriesRedux");
  const { actions: auctions_actions } = require("redux/AuctionRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchConsignments: async (token, user_id = null) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchConsignments(dispatch, token, user_id);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchConsignmentStatus: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchConsignmentStatus(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchMaterialCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await material_categories_actions.fetchMaterialCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchFleetCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await fleet_categories_actions.fetchFleetCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    publishConsignment: async (token, user_id, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.publishConsignment(
        dispatch,
        token,
        user_id,
        body
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearConsignments: () => dispatch({ type: "CLEAR_CONSIGNMENTS" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },

    fetchPaymentDetail: async (token, params, url) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await auctions_actions.fetchPaymentsDetail(
        dispatch,
        token,
        params,
        url
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearPaymentDetail: () => dispatch({ type: "CLEAR_PAYMENT_DETAIL" }),
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(ConsignmentsContainer);
