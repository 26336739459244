import { Constant } from "common";

export const PaymentApi = {

    fetchPayments: (token, params, url) => {
        try {
            return fetch(`${Constant.API_URL}/orders/get-payments/${url}${params}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    updatePayments: (token, payment_id, body) => {
        try {
            return fetch(`${Constant.API_URL}/orders/update-payment/${payment_id}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        } catch (error) {
            return { status_code: 500, ...error };
        }
    },

    verifyPaymentSelf: (token, order_id, consignment_id) => {
        try {
            return fetch(`${Constant.API_URL}/orders/checkorder/${order_id}?consigment_id=${consignment_id}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            }).then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        } catch(error) {
            return { status_code: 500, ...error };
        }
    },

    fetchOrderDetail: (token, order_id) => {
        try {
            return fetch(`${Constant.API_URL}/orders/details/${order_id}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    createOrder: (token, body) => {
        try {
            return fetch(`${Constant.API_URL}/orders`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(body),
            })
              .then(async (response) => {
                if (response.ok) {
                  return response.json();
                }
                var error_message = await response.json().then((json) => {
                  if (typeof json.message === "object") {
                    for (const index in json.message) {
                      return json.message[index];
                    }
                  } else {
                    return json;
                  }
                });
                throw new Error(JSON.stringify(error_message));
              })
              .then((json) => {
                return { status_code: 200, ...json };
              })
              .catch((error) => {
                return { status_code: 500, ...error };
              });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    verifyPayments: (token, body) => {
        try {
            return fetch(`${Constant.API_URL}/orders/verify-payments`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(body),
            })
              .then(async (response) => {
                if (response.ok) {
                  return response.json();
                }
                var error_message = await response.json().then((json) => {
                  if (typeof json.message === "object") {
                    for (const index in json.message) {
                      return json.message[index];
                    }
                  } else {
                    return json;
                  }
                });
                throw new Error(JSON.stringify(error_message));
              })
              .then((json) => {
                return { status_code: 200, ...json };
              })
              .catch((error) => {
                return { status_code: 500, ...JSON.parse(error.message) };
              });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    fetchRefund: (token) => {
        try {
            return fetch(`${Constant.API_URL}/payments/api/refund`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    createRefund: (token, body) => {
        try {
            return fetch(`${Constant.API_URL}/payments/api/refund`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...JSON.parse(error.message) };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

}