import React from 'react';
import { Detail, DetailContent, Modal } from 'components';
import moment from 'moment';
import { connect } from 'react-redux';
import "./styles.scss";
import { Images } from 'common';

class RefundDetailContainer extends React.PureComponent {
    
    render() {

        const { data, isVisible } = this.props;
        const { innerWidth } = window;
        console.log(data)
        
        return(
            <Modal
                reference={(ref) => this.rejection_modal = ref}
                isVisible={isVisible}
                className="modal__full modal__form modal__detail"
            >
                <button className={`button__link modal__detail__closer`} onClick={() => this.props.onClose()}>
                    <img src={Images.close} alt="close" />
                </button>

                <Detail
                    style={{ width: '100%', margin: 0 }}
                    className="detail__modal"
                    containerClassName="detail__modal__container"
                >
                    <DetailContent
                        style={innerWidth < 768 ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 } : { borderBottomLeftRadius: 10, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        header="Refund Detail"
                    >
                        <div className="details__item" style={{ paddingTop: 20 }}>
                            <h4 className="details__item__header">Refund</h4>
                            <div className="details__item__group">
                                <p className="label">Refund ID</p>
                                <p className="text">{data ? data.refund_id : ""}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Payment ID</p>
                                <p className="text">{data ? data.payment_id : ""}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Amount</p>
                                <p className="text">{data ? (data.amount)/100 : ""}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Date</p>
                                <p className="text">{data ? moment(data.created_at).format("DD MMM YYYY") : ""}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Status</p>
                                <p className="text">{data ? data.status === 155 ? "Pending" : data.status === 156 ? "Processed" : "Failed" : ""}</p>
                            </div>
                        </div>
                    </DetailContent>
                </Detail>
                
            </Modal>
        )
    }

}

const mapsStateToProps = ({ payment }) => {
    return {
        refunds: payment.refund_lists
    }
}
export default connect(mapsStateToProps)(RefundDetailContainer);