import { Constant } from "common";

export const InvoiceAPI = {
  fetchUsersAuctions: async (token, user_id) => {
    try {
      return fetch(`${Constant.API_URL}/payments/api/invoices?user_id=${user_id}`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      console.log(error.response);
      return { status_code: 500, ...error };
    }
  },

  fetchAdminAuctions: async (token) => {
    try {
      return fetch(`${Constant.API_URL}/payments/api/invoices`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  fetchAuctions: async (token) => {
    try {
      return fetch(`${Constant.API_URL}/auctions`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  invoiceDownload: async(id, token) => {
    try{
      console.log("auction id" , id)
      return fetch(`${Constant.API_URL}/payments/api/download/invoice/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(async(response)=>{
        if (!response.ok) {
          throw new Error('Failed to download invoice');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download' , `Invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    }
    catch(error) {
      console.error('Invoice download error:', error);
      throw new Error('Invoice download failed');
    }
  }
};
